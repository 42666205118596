import $ from '../core/Dom';
import Viewport from '../core/Viewport';

let lastScroll = 0;
let scrollingUp = false;
let resetId = 0;

const html = $('html');

const reset = () => {
    scrollingUp = false;
    html.removeClass('scroll-up');
};

const delayedReset = () => {
    clearTimeout(resetId);
    resetId = setTimeout(reset, 3000);
};

const onScroll = () => {
    const currentScroll = Viewport.scrollTop;
    if ((currentScroll >= lastScroll || currentScroll <= 0) && scrollingUp) {
        reset();
    } else if (currentScroll < lastScroll && currentScroll > 0) {
        if (!scrollingUp) {
            scrollingUp = true;
            html.addClass('scroll-up');
        }
        delayedReset();
    }
    lastScroll = currentScroll;
};

const init = () => {
    Viewport.on('scroll', onScroll);
};

export default {
    init
};
