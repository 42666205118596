import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';
import request from '../core/request';

export default el => {

    const dom = $(el);
    const close = dom.find('[data-close]');
    const content = dom.find('[data-content]');

    let open = false;
    let activeElement;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (open && key === 27) {
            onClose();
        }
    };

    const focusOutHandler = e => {
        if (!e.relatedTarget || !el.contains(e.relatedTarget)) {
            close.get(0).focus();
        }
    };

    const onOpen = (e, data) => {
        activeElement = data.button || document.activeElement;
        request.get(`/popup/${data.id}`).then(res => {
            const { status, text } = res || {};
            if (status === 200 && text) {
                content.html(text);
                dom.toggleClass('half', !content.find('img').length);
                dom.attr('hidden', null);
                open = true;
                document.addEventListener('keyup', keyHandler);
                el.addEventListener('focusout', focusOutHandler);
                close.get(0).focus();
            }
        }, error => {
            console.error(error);
        });
    };

    const onClose = () => {
        dom.attr('hidden', '');
        open = true;
        document.removeEventListener('keyup', keyHandler);
        el.removeEventListener('focusout', focusOutHandler);
        activeElement.focus();
    };

    const init = () => {
        Dispatch.on(Events.POPUP_OPEN, onOpen);
        close.on('click', onClose);
    };

    const destroy = () => {
        Dispatch.off(Events.POPUP_OPEN, onOpen);
        close.off('click', onClose);
        document.removeEventListener('keyup', keyHandler);
        el.removeEventListener('focusout', focusOutHandler);
    };

    return {
        init,
        destroy
    };
};
