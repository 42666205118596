import $ from '../core/Dom';

const VIDEO_TIMEOUT = 1000;

export default (el, { sources }) => {

    let timer;
    let intersecting = false;

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const cantPlay = () => {
        el.hidden = true;
        clearTimer();
    };

    if (!el.canPlayType || !el.canPlayType('video/mp4')) {
        cantPlay();
        return;
    }

    const setTimer = () => {
        clearTimer();
        timer = setTimeout(cantPlay, VIDEO_TIMEOUT);
    };

    const showVideo = () => {
        el.classList.remove('opacity-0');
    };

    const onTimeUpdate = e => {
        if (!timer) {
            return;
        }
        const { currentTime } = e.target;
        if (!!currentTime && currentTime > 0.001) {
            clearTimer();
            showVideo();
        }
    };

    const onLoadStart = e => {
        if (!timer) {
            return;
        }
        setTimer();
    };

    el.addEventListener('loadstart', onLoadStart);
    el.addEventListener('loadedmetadata', onLoadStart);
    el.addEventListener('loadeddata', onLoadStart);
    el.addEventListener('canplay', onLoadStart);
    el.addEventListener('timeupdate', onTimeUpdate);

    const playAndCatch = () => {
        try {
            const promise = el.play();
            if (promise !== undefined) {
                promise.then(() => {
                    clearTimer();
                    showVideo();
                }).catch(e => {
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                        cantPlay();
                    }
                });
            }
        } catch (error) {
            console.error(error);
            cantPlay();
        }
    };

    const swapSource = ({ src }) => {
        if (el.src === src) {
            return;
        }
        el.src = src;
        playAndCatch();
    };

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            console.log('intersecting play');
            intersecting = true;
            playAndCatch();
        } else {
            console.log('not intersecting pause');
            intersecting = false;
            el.pause();
        }
    };

    const observer = new IntersectionObserver(onIntersectionChange, {
        threshold: 0,
        root: null
    });

    observer.observe(el);

    if (sources.length > 1) {

        sources.forEach(source => {
            const mq = window.matchMedia(source.media);
            const onChange = e => {
                if (e.matches) {
                    swapSource(source);
                }
            };
            try {
                mq.addEventListener('change', onChange);
            } catch (error) {
                mq.addListener(onChange);
            }
            // Sets initial breakpoint
            if (mq.matches) {
                swapSource(source);
            }
        });

    } else {

        swapSource(sources[0]);

    }

    setTimer();
};
