import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';

export default el => {

    const touchpoints = $(el).find('[data-touchpoint]');

    const onClick = e => {
        const target = $(e.triggerTarget);
        Dispatch.emit(Events.POPUP_OPEN, { id: target.data('touchpoint'), button: target });
    };

    const init = () => {
        touchpoints.on('click', onClick);
    };

    const destroy = () => {
        touchpoints.off('click', onClick);
    };

    return {
        init,
        destroy
    };
};
