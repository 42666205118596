import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    let observerIn;
    // let observerOut;
    let progress;

    const onIntersectionIn = entries => {
        const intersecting = entries.filter(entry => entry.isIntersecting);
        if (intersecting.length) {
            gsap.to(el, { duration: 2.5, scaleX: progress, ease: 'power3.out' });
        }
    };

    /* const onIntersectionOut = entries => {
        const intersecting = entries.filter(entry => entry.isIntersecting);
        if (!intersecting.length) {
            gsap.set(el, { scaleX: 0 });
        }
    }; */

    const init = () => {
        progress = parseFloat($(el).data('progress'));
        gsap.set(el, { scaleX: 0 });
        observerIn = new IntersectionObserver(onIntersectionIn, {
            threshold: 0,
            root: null,
            rootMargin: '-30% 0%'
        });
        observerIn.observe(el);

        /* observerOut = new IntersectionObserver(onIntersectionOut, {
            threshold: 0,
            root: null
        });
        observerOut.observe(el); */
    };

    const destroy = () => {
        observerIn.unobserve(el);
        // observerOut.unobserve(el);
    };

    return {
        init,
        destroy
    };
};
