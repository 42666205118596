import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {
    const html = $('html');
    const dom = $(el);
    const toggle = dom.find('[data-toggle]').get(0);
    const checkbox = dom.find('[data-checkbox]');
    const imageToReveal = dom.find('[data-image]').get(1);

    let xTo;
    let yTo;

    const onToggle = () => {
        const value = checkbox.get(0).checked ? 1 : 0;
        gsap.to(imageToReveal, { duration: 0.65, opacity: value, ease: 'power2.inOut' });
    };

    const onMouseMove = e => {
        const rect = el.getBoundingClientRect();
        const x = e.clientX - rect.x;
        const y = e.clientY - rect.y;
        xTo(x);
        yTo(y);
    };

    const onMouseLeave = e => {
        if (html.hasClass('using-mouse')) {
            console.log('onMouseLeave');
            document.removeEventListener('mousemove', onMouseMove);
            gsap.to(toggle, {duration: 0.6, left: '50%', top: '50%', ease: 'power3'});
        }
    };

    const onMouseEnter = () => {
        if (html.hasClass('using-mouse')) {
            console.log('onMouseEnter');
            gsap.killTweensOf(toggle);
            xTo = gsap.quickTo(toggle, 'left', {duration: 0.6, ease: 'power3'});
            yTo = gsap.quickTo(toggle, 'top', {duration: 0.6, ease: 'power3'});
            document.addEventListener('mousemove', onMouseMove);
        }
    };

    const init = () => {
        checkbox.on('change', onToggle);
        dom.on('mouseenter', onMouseEnter);
        dom.on('mouseleave', onMouseLeave);
    };

    const destroy = () => {
        checkbox.off('change', onToggle);
        dom.off('mouseenter', onMouseEnter);
        dom.off('mouseleave', onMouseLeave);
        document.removeEventListener('mousemove', onMouseMove);
    };

    return {
        init,
        destroy
    };
};
