import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import MediaControl from '../lib/MediaControl';

const instances = [];

export default el => {
    const dom = $(el);
    const cursor = dom.find('[data-cursor]').get(0);

    let xTo;
    let yTo;
    let audio;
    let playing = false;
    let loaded = false;

    const onMouseMove = e => {
        xTo(e.offsetX);
        yTo(e.offsetY);
    };

    const onMouseEnter = () => {
        gsap.killTweensOf(cursor);
        xTo = gsap.quickTo(cursor, 'x', { duration: 0.3, ease: 'power3' });
        yTo = gsap.quickTo(cursor, 'y', { duration: 0.3, ease: 'power3' });
        dom.on('mousemove', onMouseMove);
    };

    const getCenter = () => ({ x: dom.width() / 2, y: dom.height() / 2 });

    const onMouseLeave = () => {
        dom.off('mousemove', onMouseMove);
        const center = getCenter();
        gsap.killTweensOf(cursor);
        gsap.to(cursor, { duration: 0.6, x: center.x, y: center.y, ease: 'power3' });
    };

    const onCanPlay = () => {
        if (!loaded) {
            loaded = true;
            audio.play();
        }
    };

    const onClick = () => {
        if (loaded) {
            audio.currentTime = 0;
            audio.play();
        } else {
            audio = new Audio(dom.data('src'));
            audio.addEventListener('canplay', onCanPlay);
        }
    };

    const onResize = () => {
        const center = getCenter();
        gsap.set(cursor, { x: center.x, y: center.y });
    };

    const init = () => {
        gsap.set(cursor, getCenter());
        $(cursor).removeClass('hidden');

        dom.on('mouseenter', onMouseEnter);
        dom.on('mouseleave', onMouseLeave);
        dom.on('click', onClick);

        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        loaded = false;
        if (audio) {
            audio.pause();
            audio.removeEventListener('canplay', onCanPlay);
        }

        dom.off('mousemove');
        dom.off('mouseenter');
        dom.off('mouseleave');
        dom.off('click');

        Viewport.on('resize', onResize);
        gsap.killTweensOf(cursor);
    };

    return {
        init,
        destroy
    };
};
