import Viewport from '../core/Viewport';

export default el => {

    const onResize = () => {
        const rect = el.getBoundingClientRect();
        document.documentElement.style.setProperty('--heroOffset', `${Math.floor(rect.top + window.scrollY)}px`);
    };

    const init = () => {
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.on('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
