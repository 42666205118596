/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const MENU_OPEN = 'MENU_OPEN';
export const POPUP_OPEN = 'POPUP_OPEN';
export const TOUCHPOINT_FILTER = 'TOUCHPOINT_FILTER';
