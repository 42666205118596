import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import MediaControl from '../lib/MediaControl';

const instances = [];

export default el => {
    const dom = $(el);
    const progress = dom.find('[data-progress]').get(0);
    const cursor = dom.find('[data-cursor]').get(0);
    const scrubber = dom.find('[data-scrubber]');
    const gui = dom.find('[data-gui]').get(0);
    const time = dom.find('[data-time]');
    const slider = dom.find('input[type="range"]').get(0);
    const mute = dom.find('svg .mute');
    const low = dom.find('svg .low');
    const medium = dom.find('svg .medium');
    const high = dom.find('svg .high');
    const button = dom.find('[data-button]');
    const iconPlay = button.find('[data-play]');
    const iconPause = button.find('[data-pause]');
    const waveform = dom.find('.waveform-inverted').get(0);

    let xTo;
    let yTo;
    let audio;
    let playing = false;
    let loaded = false;

    const progressTween = gsap.to(progress, { paused: true, duration: 1, ease: 'linear', scaleX: 1 });
    const waveformTween = gsap.to(waveform, { paused: true, duration: 1, ease: 'linear', clipPath: 'inset(0 0% 0 0)' });

    const padNumber = number => (number > 9 ? number : `0${number}`);

    const onVolumeAdjust = () => {
        slider.style.setProperty('--value', slider.value);
        const percent = slider.value / slider.max;
        high.toggleClass('hidden', percent < 0.66);
        medium.toggleClass('hidden', percent < 0.33);
        low.toggleClass('hidden', percent === 0);
        mute.toggleClass('hidden', percent > 0);
        if (audio) {
            audio.volume = percent;
        }
    };

    const initSlider = () => {
        slider.style.setProperty('--value', slider.value);
        slider.style.setProperty('--min', slider.min);
        slider.style.setProperty('--max', slider.max);
        slider.addEventListener('input', onVolumeAdjust);
    };

    const onMouseMove = e => {
        xTo(e.offsetX);
        yTo(e.offsetY);
    };

    const onMouseEnter = () => {
        gsap.killTweensOf(cursor);
        xTo = gsap.quickTo(cursor, 'x', { duration: 0.3, ease: 'power3' });
        yTo = gsap.quickTo(cursor, 'y', { duration: 0.3, ease: 'power3' });
        scrubber.on('mousemove', onMouseMove);
    };

    const getCenter = () => ({ x: scrubber.width() / 2, y: scrubber.height() / 2 });

    const onMouseLeave = () => {
        scrubber.off('mousemove', onMouseMove);
        const center = getCenter();
        gsap.killTweensOf(cursor);
        gsap.to(cursor, { duration: 0.6, x: center.x, y: center.y, ease: 'power3' });
    };

    const initCursor = () => {
        gsap.set(cursor, getCenter());
        $(cursor).removeClass('hidden');
        scrubber.on('mouseenter', onMouseEnter);
        scrubber.on('mouseleave', onMouseLeave);
    };

    const onScrub = e => {
        if (e.offsetX) {
            const percent = e.offsetX / scrubber.width();
            audio.currentTime = audio.duration * percent;
        } else {
            toggleState();
        }
    };

    const onStateChanged = e => {
        console.log(e);
        playing = e.type === 'play' || e.type === 'playing';
        if (playing) {
            MediaControl.play(audio);
        }
        iconPlay.toggleClass('hidden', playing);
        iconPause.toggleClass('hidden', !playing);
    };

    const onTimeUpdate = () => {
        const minutes = padNumber(Math.floor(audio.currentTime / 60));
        const seconds = padNumber(Math.floor(audio.currentTime - minutes * 60));
        time.text(`${minutes}:${seconds}`);
    };

    const updateProgress = () => {
        const percent = audio.currentTime / audio.duration;
        progressTween.progress(percent);
        waveformTween.progress(percent);
    };

    const onProgress = () => {
        if (playing) {
            updateProgress();
        }
        requestAnimationFrame(onProgress);
    };

    const onSeeked = () => {
        if (!playing) {
            updateProgress();
            audio.play();
        }
    };

    const onCanPlay = () => {
        if (!loaded || playing) {
            loaded = true;
            audio.play();
            requestAnimationFrame(onProgress);
        }
    };

    const toggleState = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play();
        }
    };

    const initAudio = () => {
        audio = new Audio(dom.data('src'));
        audio.addEventListener('canplay', onCanPlay);
        audio.addEventListener('timeupdate', onTimeUpdate);
        audio.addEventListener('play', onStateChanged);
        audio.addEventListener('playing', onStateChanged);
        audio.addEventListener('pause', onStateChanged);
        audio.addEventListener('ended', onStateChanged);
        audio.addEventListener('seeked', onSeeked);

        scrubber.on('click', onScrub);
        button.on('click', toggleState);
        MediaControl.addPlayer(audio);
    };

    const onResize = () => {
        const center = getCenter();
        gsap.set(cursor, { x: center.x, y: center.y });
    };

    const onLoadAudio = () => {
        Viewport.off('resize', onResize);
        scrubber.off('click', onLoadAudio);
        scrubber.off('mousemove', onMouseMove);
        scrubber.off('mouseenter', onMouseEnter);
        scrubber.off('mouseleave', onMouseLeave);
        gsap.killTweensOf(cursor);
        gsap.to(cursor, {
            duration: 0.5,
            scale: 0,
            opacity: 0,
            ease: 'back.in',
            onComplete: () => {
                scrubber.removeClass('cursor-none').addClass('cursor-ew-resize');
                gsap.to(gui, { delay: 0.1, duration: 0.5, autoAlpha: 1, ease: 'power2.inOut' });
                initAudio();
            }
        });
    };

    const init = () => {
        initSlider();
        initCursor();
        scrubber.on('click', onLoadAudio);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        loaded = false;
        playing = false;
        if (audio) {
            audio.pause();
            audio.removeEventListener('canplay', onCanPlay);
            audio.removeEventListener('timeupdate', onTimeUpdate);
            audio.removeEventListener('play', onStateChanged);
            audio.removeEventListener('playing', onStateChanged);
            audio.removeEventListener('pause', onStateChanged);
            audio.removeEventListener('ended', onStateChanged);
            audio.removeEventListener('seeked', onSeeked);
        }
        slider.removeEventListener('input', onVolumeAdjust);
        scrubber.off('click');
        scrubber.off('mousemove');
        scrubber.off('mouseenter');
        scrubber.off('mouseleave');
        button.off('click');
        Viewport.on('resize', onResize);
        gsap.killTweensOf(cursor);
    };

    return {
        init,
        destroy
    };
};
