import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default el => {
    let Flickity;
    let flkty;
    const dom = $(el);
    const numberOfSlides = dom.find('[data-slide]').length;

    const initSlider = () => {
        if (!flkty) {
            console.log('initSlider');
            flkty = new Flickity(el.firstElementChild, {
                contain: true,
                dragThreshold: 10,
                cellAlign: 'left',
                cellSelector: '[data-slide]',
                prevNextButtons: false,
                pageDots: true,
                wrapAround: false,
                freeScroll: true,
                groupCells: true,
                freeScrollFriction: 0.045
            });

            flkty.on('dragStart', () => {
                document.ontouchmove = e => e.preventDefault();
            });

            flkty.on('dragEnd', () => {
                document.ontouchmove = () => true;
            });
        }
    };

    const destroySlider = () => {
        if (flkty) {
            console.log('destroySlider');
            flkty.destroy();
            flkty = null;
        }
    };

    const onResize = () => {
        if (numberOfSlides === 2) {
            if (Viewport.width < 750) {
                initSlider();
            } else {
                destroySlider();
            }
        } else if (numberOfSlides === 3) {
            if (Viewport.width < 1200) {
                initSlider();
            } else {
                destroySlider();
            }
        }
    };

    const init = () => {
        loadFlickity(module => {
            Flickity = module;
            dom.removeClass('overflow-auto').addClass('overflow-hidden');
            Viewport.on('resize', onResize);
            onResize();
        });
    };

    const destroy = () => {
        destroySlider();
        dom.addClass('overflow-auto').removeClass('overflow-hidden');
        Viewport.off('breakpoint', onResize);
    };

    return {
        init,
        destroy
    };
};
