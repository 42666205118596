import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default el => {
    const dom = $(el);
    const wrap = dom.find('[data-wrap]').get(0);
    const image = dom.find('[data-image]').get(0);

    let rect;
    let active = false;
    let animation;

    const initAnimation = () => {
        animation = gsap.fromTo(image, { clipPath: `inset(5vh ${rect.x}px)` }, {
            duration: 1,
            clipPath: 'inset(0vh 0px)',
            ease: 'none',
            scrollTrigger: {
                trigger: image,
                start: 'top 70%',
                end: 'center 50%',
                scrub: true,
                markers: false,
                toggleActions: 'play reset play reset'
            }
        });
    };

    const destroyAnimation = () => {
        if (animation) {
            animation.kill();
            animation = null;
        }
    };

    const onResize = () => {
        if (Viewport.width >= 750) {
            destroyAnimation();
            rect = wrap.getBoundingClientRect();
            gsap.set(image, { clipPath: `inset(5vh ${rect.x}px)` });
            if (!active) {
                active = true;
            }
            initAnimation();
        } else if (Viewport.width < 750 && active) {
            destroyAnimation();
            gsap.set(image, { clipPath: 'inset(0 0 0 0)' });
            active = false;
        }
    };

    const init = () => {
        loadScrollTrigger(module => {
            gsap.registerPlugin(module.default);
            Viewport.on('resize', onResize);
            onResize();
        });
    };

    const destroy = () => {
        destroyAnimation();
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
