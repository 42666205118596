import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';

export default el => {

    const filters = $(el).find('[data-slug]');

    const onFilter = e => {
        e.preventDefault();
        const target = $(e.triggerTarget);
        filters.removeClass('selected');
        target.addClass('selected');
        Dispatch.emit(Events.TOUCHPOINT_FILTER, { target: el.nextElementSibling.firstElementChild, type: target.data('slug') });
    };

    const init = () => {
        filters.on('click', onFilter);
    };

    const destroy = () => {
        filters.off('click', onFilter);
    };

    return {
        init,
        destroy
    };
};
