import gsap from 'gsap';
import $ from '../core/Dom';

const init = () => {
    $('[data-scroll-target]').on('click', e => {
        const href = e.triggerTarget.getAttribute('href');
        if (href && href.indexOf('#') !== -1) {
            const target = $(href);
            if (target.length) {
                e.preventDefault();
                gsap.to(window, { duration: 1, scrollTo: { y: target.position().top, autoKill: false }, ease: 'power2.inOut', onComplete: () => { target.get(0).focus(); } });
            }
        }
    });
};

export default {
    init
};
