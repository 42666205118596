import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const loadScrollTrigger = require('bundle-loader?lazy&name=[name]!gsap/ScrollTrigger');

export default el => {

    const proxy = {
        progress: 0
    };
    let animation;

    const initAnimation = () => {
        animation = gsap.fromTo(proxy, { progress: 0 }, {
            duration: 1,
            progress: 100,
            ease: 'sine.inOut',
            onUpdate: () => {
                gsap.set(el, { transformOrigin: `50% ${proxy.progress}%` });
            },
            scrollTrigger: {
                trigger: el,
                start: 'top 75%',
                end: 'bottom 25%',
                scrub: true
            }
        });
    };

    const destroyAnimation = () => {
        if (animation) {
            animation.kill();
            animation = null;
        }
    };

    const init = () => {
        gsap.set(el, { scale: 1.2, transformOrigin: '50% 0%' });
        loadScrollTrigger(module => {
            gsap.registerPlugin(module.default);
            initAnimation();
        });
    };

    const destroy = () => {
        destroyAnimation();
    };

    return {
        init,
        destroy
    };
};
