import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const targets = $(document.body);
const elements = $('[data-body-classes]');
let classesAdded = '';
let observer;
let active = false;

const onIntersectionChange = entries => {
    const intersecting = entries.filter(entry => entry.isIntersecting);
    if (classesAdded === '' && intersecting.length) {
        classesAdded = $(intersecting[0].target).data('body-classes');
        targets.addClass(classesAdded);
    } else if (classesAdded !== '' && intersecting.length === 0) {
        targets.removeClass(classesAdded);
        classesAdded = '';
    }
};

const onResize = () => {
    if (elements.length) {
        const rect = elements.get(0).getBoundingClientRect();
        const top = rect.top + window.scrollY;
        if (!active && top > Viewport.height) {
            active = true;
            elements.each(node => {
                const el = $(node);
                el.removeClass(el.data('body-classes'));
                observer.observe(node);
            });
        } else if (active && top <= Viewport.height) {
            active = false;
            elements.each(node => {
                const el = $(node);
                el.addClass(el.data('body-classes'));
                observer.unobserve(node);
            });
        }
    }
};

const init = () => {
    observer = new IntersectionObserver(onIntersectionChange, {
        threshold: 0.3,
        root: null
    });
    Viewport.on('resize', onResize);
    onResize();

};

export default {
    init
};
