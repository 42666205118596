import gsap from 'gsap';
import $ from '../core/Dom';
import MediaControl from '../lib/MediaControl';

const loadVimeo = require('bundle-loader?lazy&name=[name]!@vimeo/player');

const instances = [];

export default el => {
    const dom = $(el);
    const button = dom.find('[data-button]');
    const embed = dom.find('[data-embed]');
    const controls = dom.find('[data-controls]').get(0);

    let player;

    const onPlay = () => {
        MediaControl.play(player);
    };

    const loadPlayer = () => {
        button.off('click', loadPlayer);
        loadVimeo(module => {
            const VimeoPlayer = module.default;
            embed.html(embed.data('embed'));

            player = new VimeoPlayer(embed.find('iframe').get(0));
            MediaControl.addPlayer(player);
            player.on('play', onPlay);

            gsap.to(controls, {
                duration: 0.5,
                autoAlpha: 0,
                ease: 'power2.inOut'
            });
        });
    };

    const init = () => {
        button.on('click', loadPlayer);
    };

    const destroy = () => {
        button.off('click');
    };

    return {
        init,
        destroy
    };
};
