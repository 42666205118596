import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const buttons = $(el).find('[aria-expanded]');

    const open = button => {
        const content = $(button.data('answer')).get(0);
        const chevron = button.find('[data-chevron]').get(0);
        button.attr('aria-expanded', 'true');
        button.attr('aria-label', 'Skjul svar');
        gsap.set(content, { height: 'auto' });
        gsap.from(content, {
            height: 0,
            duration: 0.35,
            ease: 'power2.inOut',
            onComplete: () => {
                gsap.set(content, { height: 'auto' });
            }
        });
        gsap.to(chevron, { scaleY: -1, duration: 0.35, ease: 'power2.inOut' });
    };

    const close = button => {
        const content = $(button.data('answer')).get(0);
        const chevron = button.find('[data-chevron]').get(0);
        button.attr('aria-expanded', 'false');
        button.attr('aria-label', 'Vis svar');
        gsap.to(content, { height: 0, duration: 0.35, ease: 'power2.inOut', clearProps: 'height' });
        gsap.to(chevron, { scaleY: 1, duration: 0.35, ease: 'power2.inOut' });
    };

    const toggle = e => {
        const target = $(e.triggerTarget);
        if (target.attr('aria-expanded') === 'true') {
            close(target);
        } else {
            open(target);
        }
    };

    const init = () => {
        buttons.on('click', toggle);
    };

    const destroy = () => {
        buttons.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
